import React from 'react'
import {RingLoader} from 'react-spinners'
import logo from "../assets/img/logo.png";

function LoadComponent({Component}) {
  return (
    <React.Suspense fallback={
    <div className='position-fixed w-100 h-100 d-flex justify-content-center align-items-center bg-white top-0 flex-column'>
       <img src={logo} style={{ width: 150 }} alt="logo" />
      <RingLoader color="#36d7b7" />
    </div>
    }>
      <Component/>
      </React.Suspense>
  )
}

export default LoadComponent
