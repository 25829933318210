import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoadComponent from "./LoadComponent";

const Client = React.lazy(() => import("./../apps/Client"));

const Home = React.lazy(() => import("./../pages/Home"));

const Tranings = React.lazy(() => import("../pages/Tranings"));
const NoPage = React.lazy(() => import("../pages/NoPage"));
const ContactUs = React.lazy(() => import("../pages/ContactUs"));
const Services = React.lazy(() => import("../pages/Services"));
const Pionniers = React.lazy(() =>
  import("../components/bootcamp/Pionniers")
);
const JourneyMan = React.lazy(() =>
  import("../components/bootcamp/JourneyMan")
);
const Grandmaster = React.lazy(() =>
  import("../components/bootcamp/Grandmaster")
);

function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoadComponent Component={Client} />}>
            <Route index element={<LoadComponent Component={Home} />} />

            <Route
              path="trainings"
              element={<LoadComponent Component={Tranings} />}
            >
              <Route index element={<LoadComponent Component={Pionniers} />} />
              <Route
                path="JourneyMan"
                element={<LoadComponent Component={JourneyMan} />}
              />
              <Route
                path="Grandmaster"
                element={<LoadComponent Component={Grandmaster} />}
              />
            </Route>

            <Route
              path="/services"
              element={<LoadComponent Component={Services} />}
            />
            <Route
              path="/contact-us"
              element={<LoadComponent Component={ContactUs} />}
            />
            <Route path="*" element={<LoadComponent Component={NoPage} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Router;
