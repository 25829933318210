import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import navbarEn from "../constants/transalations/english/navbarEn.json";
import navbarAr from "../constants/transalations/arabic/navbarAr.json";
import navbarFr from "../constants/transalations/french/navbarFr.json";
import homeEn from "../constants/transalations/english/homeEn.json";
import homeAr from "../constants/transalations/arabic/homeAr.json";
import homeFr from "../constants/transalations/french/homeFr.json";
import footerAr from "../constants/transalations/arabic/footerAr.json";
import footerFr from "../constants/transalations/french/footerFr.json";
import footerEn from "../constants/transalations/english/footerEn.json";
import trainingAr from "../constants/transalations/arabic/trainingAr.json";
import trainingFr from "../constants/transalations/french/trainingFr.json";
import trainingEn from "../constants/transalations/english/trainingEn.json";
import contactAr from "../constants/transalations/arabic/contactAr.json";
import contactFr from "../constants/transalations/french/contactFr.json";
import contactEn from "../constants/transalations/english/contactEn.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: { ...navbarEn , ...homeEn , ...footerEn, ...trainingEn, ...contactEn},
      },
      ar: {
        translation: { ...navbarAr, ...homeAr, ...footerAr, ...trainingAr,...contactAr},
      },
      fr: {
        translation: { ...navbarFr,...homeFr , ...footerFr, ...trainingFr,...contactFr},
      },
    },
    
    lng: localStorage.getItem('lg') ? localStorage.getItem('lg') : "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
