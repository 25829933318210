import Router from "./router/Router";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./utils/i18n";
import { createContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
export const RtlContext = createContext();
function App() {
  const { i18n } = useTranslation();
  const isRtl = useMemo(
    () => (i18n?.languages[0] === "ar" ? "rtl" : "ltr"),
    [i18n?.languages]
  );
  console.log(i18n?.languages[0]);
  return (
    <RtlContext.Provider value={isRtl}>
      <div className="App" style={{ direction: isRtl }}>
        <Router />
      </div>
    </RtlContext.Provider>
  );
}

export default App;
